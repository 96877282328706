<template>
  <a class="link" v-bind="$attrs"><slot /></a>
</template>

<script>
export default {
  name: "Link"
};
</script>
<style lang="scss">
.link {
  color: $color-link;
  transition: 0.15s ease-in-out;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $color-red;
  }

  @include click-element(0.4rem);
}
</style>
