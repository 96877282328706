<template>
  <main class="layout" :class="{ 'full-height': fullHeight }">
    <div class="layout__header">
      <div class="inner">
        <div
          class="logo"
          :style="brand.logo && { backgroundImage: `url(${brand.logo})` }"
        ></div>
      </div>
    </div>
    <div class="layout__content">
      <div class="inner">
        <div class="layout__main">
          <div v-if="back" class="layout__back" @click="handleClickBack">
            <IconLeftArrow />
          </div>
          <div v-if="withCard" class="layout__card">
            <LayoutCard />
          </div>
          <slot />
        </div>
      </div>
    </div>
    <div class="layout__footer">
      <div class="inner">
        Сделано на технологии
        <v-link
          :href="
            `https://maxma.com/loyalty?utm_source=form_maxma&amp;utm_medium=form&amp;utm_campaign=${$route.params.brand}&amp;utm_content=footer`
          "
          target="_blank"
          >MAXMA.com</v-link
        >
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from "vuex";

import Link from "@/components/ui/Link.vue";
import LayoutCard from "@/components/LayoutCard.vue";
import IconLeftArrow from "@/icons/IconLeftArrow.vue";

export default {
  name: "Layout",
  components: { "v-link": Link, LayoutCard, IconLeftArrow },
  props: {
    logoSrc: String,
    back: String,
    withCard: Boolean,
    fullHeight: Boolean
  },
  computed: {
    ...mapState({
      brand: state => state.brand
    })
  },
  methods: {
    handleClickBack() {
      this.$router.push(this.back);
    }
  }
};
</script>
<style scoped lang="scss">
.layout {
  flex: 1 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-md) {
    max-width: none;
    background-color: $color-bg;
  }

  &__header {
    border-bottom: solid 1px $color-border;
    padding: 10px 0;

    display: none;

    @media (min-width: $screen-md) {
      display: block;
    }
  }

  &__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: hidden;

    @media (min-width: $screen-md) {
      margin: 64px 0;
    }
  }

  &__footer {
    padding: 10px 0 20px;
    font-size: 14px;
    line-height: 1.33;
    text-align: center;

    color: $color-text-pseudo;
    border-top: 1px solid $color-border;

    @media (min-width: $screen-md) {
      text-align: left;
      padding: 18px 0;
      border-top: 0;
    }

    .link {
      font-weight: 500;
    }
  }

  &__footer,
  &__header {
    background: white;
  }

  &__footer {
    z-index: 10;
    position: relative;
    margin: auto 0 0;
  }

  &__main {
    position: relative;
    background: white;
    border-radius: 19px;
    width: 100%;

    @media (min-width: $screen-md) {
      overflow: hidden;
    }
  }

  &__card {
    position: relative;
    margin: 50px auto;
    padding: 0 44px;
    max-width: 410px;

    @media (min-width: $screen-md) {
      padding: 0;
      margin-top: 20px;
      position: absolute;
      top: 330px;
      right: 0;
      max-width: none;
      width: 51%;
    }
  }

  &.full-height {
    overflow: hidden;

    .layout__content {
      height: 100%;
    }

    .inner__content {
      margin: auto 0;
    }

    .layout__content {
      .layout__main,
      .inner {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 1;
        overflow: visible;

        @media (min-width: $screen-md) {
          justify-content: flex-start;
        }
      }

      .layout__main {
        margin-top: 24px;
        @media (min-width: $screen-md) {
          margin-top: 0;
        }
      }

      .layout__card {
        margin: auto 0;
      }
    }
  }

  &__back {
    display: none;

    @media (min-width: $screen-md) {
      @include click-element();
      position: absolute;
      z-index: 10;
      top: 30px;
      left: 60px;
      cursor: pointer;
      display: block;
    }
  }
}

.inner {
  width: 100%;
  max-width: 448px;
  margin: 0 auto;

  @media (min-width: $screen-md) {
    max-width: 1233px;
    padding: 0 24px;
    box-sizing: border-box;
  }
}

.logo {
  width: 214px;
  height: 67px;
  background: no-repeat center center / contain;
}
</style>
